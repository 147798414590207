import React, { Component } from 'react';
import TextLoop from "react-text-loop";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class App extends Component {
  render() {
    return (
      <div className="Start">
        <div id="header">
          <div class="top">
              <div id="logo">
                <span class="image avatar48" />
                <h1 id="title">Christina Tang</h1>
                <p>Software Engineer</p>
              </div>

              <nav id="nav">
                <ul>
                  <li><a href="#portfolio" id="top-link">Portfolio</a></li>
                  <li><a href="https://www.linkedin.com/in/christinatang-/" target="newwindow" id="top-link">Linkedin</a></li>
                </ul>
              </nav>
          </div>
        </div>
        <div className="Home">
                <div id="main">

        					<section id="top" className="one header--Gradient">
        						<div className="container">

        							<header className="header-text">
                        <p>Hello, I am</p>
                        <h1>Christina Tang</h1>
                        <p>I use{" "}
                          <TextLoop>
                              <span> Django/Python</span>
                              <span> HTML/CSS/Javascript</span>
                              <span> React</span>
                          </TextLoop>
                        </p>
        							</header>

        							<footer id="footer">
                        <a href="https://www.linkedin.com/in/christinatang-/" target="newwindow" class="footer__Icon"><FontAwesomeIcon icon={['fab', 'linkedin']} className="fa-2x"/></a>
        							</footer>

        						</div>
        					</section>

        					<section id="portfolio" className="two">
        						<div className="container">

        							<header>
        								<h2>WORK EXPERIENCE</h2>
        							</header>

        							<p>I have been working as a Full Stack Software Engineer for ~3 years with a bachelor's degree in Computer Science from The University of Reading.
                         I have experience with a wide range of web technologies. These include Python, Django, HTML/CSS, React, MySQL.
                      </p>

                      <hr></hr>

        							<div className="row">
        								<div className="col-12 col-12-mobile">
        									<article className="item">
        										<header>
        											<h4><FontAwesomeIcon icon={['fas', 'bookmark']} className="theme-xt"/>  RUTH MISKIN TRAINING </h4>
                              <em>Software Engineer, Aug 2018 - Present</em>
                              <p><FontAwesomeIcon icon={['fas', 'chevron-right']} /> Full stack development of 3 portals and 2 public facing websites</p>
                              <p><FontAwesomeIcon icon={['fas', 'chevron-right']} /> Responsible for leading front-end development and design</p>
                              <p><FontAwesomeIcon icon={['fas', 'chevron-right']} /> Liasing with senior members of the company to determine requirements and deliver accordingly</p>
        										</header>
        									</article>
                          <article className="item">

                          <header>
                            <h4><FontAwesomeIcon icon={['far', 'bookmark']} className="theme-xt"/> TOTTERIDGE FLOWER COMPANY</h4>
                            <em>Web Developer, Oct 2017 - Feb 2018</em>
                            <p><FontAwesomeIcon icon={['fas', 'chevron-right']} /> Established an online presence for a local florist based in Totteridge and Whetstone</p>
                            <p><FontAwesomeIcon icon={['fas', 'chevron-right']} /> Solely designed, developed and deployed the website</p>
                          </header>
                        </article>

        								</div>


        								</div>

        						</div>
        					</section>
        			</div>
              </div>
      </div>

    )
  }
}

export default App;
